



























import Vue from 'vue';
import OnboardingWrapper from '@/components/Mobile/Onboarding/OnboardingWrapper.vue';
export default Vue.extend({
  components: { OnboardingWrapper },
});
